<template>
    <main-page>

        <goods-filter @change="filterChanged" class="filter"/>

        <div v-if="!loading && !goods.length" class="goods-list empty">
            暂无单品数据哦
        </div>
        <div v-else-if="loading" class="goods-list empty">
            <img src="/assets/images/loading.gif"/>
        </div>
        <div v-else class="goods-list">

            <template v-for="(item,key) in goods">

                <div class="item" :key="key">
                    <goods-item :goods="item"/>
                </div>

            </template>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
        </div>

        <paginate class="pager" :pageCount="pager.count"
                  :clickHandler="pagerClicked"
                  containerClass="paginate-container"
                  :margin-pages="1"
                  :page-range="5"
                  v-model="pager.current"
        />

    </main-page>
</template>

<script>
    import MainPage from "@/layout/MainPage";
    import GoodsFilter from "@/components/GoodsFilter";
    import GoodsItem from "@/components/GoodsItem";
    import api from "@/repo/api";
    import Paginate from "vuejs-paginate";

    export default {
        name: "Shop",
        components: {GoodsItem, GoodsFilter, MainPage, Paginate},
        data() {
            return {
                goods: [],
                filter: {},
                loading: false,
                pager: {
                    count: 0,
                    current: 1,
                }
            }
        },
        mounted() {
            this.getGoods()
        },
        methods: {
            getGoods() {
                this.loading = true

                api.getGoods(this.filter, (data) => {
                    this.loading = false

                    this.goods = data.data.data
                    let dta = data.data
                    this.goods = dta.data
                    this.pager.count = Math.round(dta.total / dta.per_page);
                    this.pager.current = dta.current_page;
                })
            },
            pagerClicked() {
                this.filter.page = this.pager.current
                this.getGoods()
            },
            filterChanged(filter) {
                this.filter = filter
                this.pager.current = 1
                this.filter.page = 1

                this.getGoods()
            },
        }
    }
</script>

<style lang="less" scoped>
    .filter {
        margin-bottom: 32px;
    }

    .goods-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &.empty {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            color: #333333;

            img {
                width: 48px;
                height: 48px;
            }
        }
    }

    .item {
        width: 304px;
        position: relative;

        margin-bottom: 32px;

        /deep/ .goods-item {

            height: 368px;

            .image-container {
                width: 304px;
                height: 304px;
            }
        }

        &:hover .col-btn {
            opacity: 1;
        }
    }

    .col-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: white;
        border-radius: 100%;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        cursor: pointer;

        span {
            font-size: 14px;
        }

        &.collected {
            opacity: 1;
        }
    }

    .pager {
        margin-bottom: 100px;
    }
</style>